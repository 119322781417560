<script setup lang="ts">
import draggable from 'vuedraggable'
import type { TaskStatus } from '../../models/taskStatus'
import type { Task } from '../../models/task'
import { computed, toRefs } from 'vue'
import { useTasksTeamStore } from '../../teamStore'
import { findSiblings, getNewLexorank } from '@/utils/board'
import { LexoRank } from 'lexorank'
import type { SortableEvent } from './types'
import Card from './Card.vue'
import StatusHeader from '@modules/tasks/components/StatusHeader.vue'

const store = useTasksTeamStore()

const props = defineProps<{
  stage: TaskStatus
  tasks: Task[]
  isHiddenColumn?: boolean
}>()
const { stage, tasks } = toRefs(props)

const sortedTasks = computed(() =>
  [...tasks.value].sort((a, b) =>
    LexoRank.parse(a.rank).compareTo(LexoRank.parse(b.rank)),
  ),
)

const handleTaskAdd = (event: SortableEvent<Task>, stage: TaskStatus) => {
  const {
    item: { _underlying_vm_ },
    newIndex,
  } = event
  const task = { ..._underlying_vm_ }
  const payload = findSiblings(newIndex, null, sortedTasks.value)
  const rank = getNewLexorank(payload)
  store.moveTaskToStage(task, stage, rank.toString(), newIndex)
}

const handleTaskMove = (event: SortableEvent<Task>, stage: TaskStatus) => {
  const {
    item: { _underlying_vm_ },
    newIndex,
    oldIndex,
  } = event
  const task = { ..._underlying_vm_ }
  const payload = findSiblings(newIndex, oldIndex, sortedTasks.value)
  const rank = getNewLexorank(payload)
  store.moveTaskToStage(task, stage, rank.toString(), newIndex)
}
</script>
<template>
  <div :class="$style.column">
    <StatusHeader
      :enable-add-task="!isHiddenColumn"
      :status="stage"
      :count="tasks.length"
      :allowed-actions="['rename', 'delete', 'hide']"
    />

    <draggable
      :class="$style.cards"
      :model-value="sortedTasks"
      animation="200"
      group="cards"
      item-key="id"
      @add="(event: SortableEvent<Task>) => handleTaskAdd(event, stage)"
      @update="(event: SortableEvent<Task>) => handleTaskMove(event, stage)"
    >
      <template #item="{ element: card }">
        <Card v-if="!isHiddenColumn" :task="card" />
      </template>
    </draggable>
  </div>
</template>
<style module lang="scss">
.column {
  display: flex;
  flex-direction: column;
  width: 340px;
  border-radius: 12px;
  flex-shrink: 0;
  border: 1px solid var(--panel-3);
  background-color: var(--panel-3);
  padding: 0px 8px;
  padding-bottom: 24px;
  .cards {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 4px;
    overflow: auto;
  }
}
</style>
