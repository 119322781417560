<script setup lang="ts">
import { ref } from 'vue'
import { Icon } from '@/components/common'
defineProps<{ type: 'folder' | string }>()

const emit = defineEmits<{
  (e: 'submit', title: string): void
}>()

const title = ref<string>('')

const onSubmit = () => {
  if (title.value) {
    emit('submit', title.value)
  }
}
</script>

<template>
  <div :class="$style.container">
    <div width="32">
      <span :class="$style.pipelineIcon">
        <Icon icon="plusSmall" :size="20" />
      </span>
    </div>

    <div>
      <input
        v-model.trim="title"
        v-focus
        :class="$style.textField"
        :placeholder="`add name for new ${type}`"
        @keypress.enter="onSubmit"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@import url(./common.module.scss);

.container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 12px;
}
</style>
