<script lang="ts" setup>
import { computed, ref, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { Asset } from '../models/asset'
import icons from '@/assets/icons'
import {
  Button,
  Confirm,
  DropDown,
  Icon,
  Popover,
  Checkbox,
  type DropDownItem,
} from '@/components/common'
import IconSelector from '@/components/common/IconSelector.vue'
import { useCollabStore } from '../store'
import { ISODate } from '@/utils/helpers'
import { reactive } from 'vue'
import ManageMembers from './ManageMembers.vue'
import Popup from '@/components/common/Popup.vue'
import User from '@/models/user'
import MoveToFolder from './MoveToFolder.vue'
import MembersPreview from './MembersPreview.vue'

const props = defineProps<{ item: Asset, checked: boolean }>()

const { item } = toRefs(props)

const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)
const membersPopup = ref<InstanceType<typeof Popup> | null>(null)
const moveToFolderPopup = ref<InstanceType<typeof Popup> | null>(null)

const router = useRouter();
const store = useCollabStore()

const rename = reactive({
  isActive: false,
  name: props.item.title,
})

const icon = computed<keyof typeof icons>(() => {
  if (props.item.type === 'folder') {
    return props.item?.icon ?? 'folder2'
  }
  return 'fileBend';
})

const color = computed<string>(() => {
  return rename.isActive || !props.item?.color ? '#1e1f24' : props.item?.color;
})

const handleRename = () => {
  rename.name.length &&
    store.updateAsset(props.item.id, { title: rename.name }).then(() => {
      rename.isActive = false
    })
}

const handleMoveFolder = (parentId: number) => {
    store.updateAsset(props.item.id, { parentId: parentId ? parentId : null })
      .then(() => {
        const {params} = router.currentRoute.value;

        return store.loadAssets({
            include: 'members,subAssetsCount',
            ...(params.folderId ? {'filter[parent_id]': Number(params.folderId)} : {'filter[no_parent]': true})
          })
      }).then(() => {
        moveToFolderPopup.value?.hide()
      })
}

const menu = computed<DropDownItem[]>(() => {
  return [
    {
    id: 'favorites',
    title: `${props.item.isFavorite ? 'Remove from ': 'Add to'} favorites`,
    handler: () => store.toggleFavorite(props.item, ! props.item.isFavorite)
  },
  {
    id: 'rename',
    title: 'Rename',
    handler: () => {
      rename.isActive = true
    },
  },
  {
    id: 'move',
    title: 'Move',
    handler: () => moveToFolderPopup.value?.show()
  },
  {
    id: 'manage',
    title: 'Manage Members',
    handler: () => membersPopup.value?.show()
  },
  {
    id: 'delete',
    title: 'Delete',
    type: 'destructive',
    handler: () =>
      confirmPopup.value?.show({
        confirmString: 'delete',
        title: `Delete ${props.item.type}`,
        message: `You’re about to delete ${props.item.type === 'folder' ? `folder with ${props.item.filesCount} files in it`: 'file'}. Are you sure? This is permanent action and can not be undone. Please, confirm your purpose by typing “delete” in the input below.`,
        actions: [
          {
            title: 'Cancel',
            theme: 'neutral',
            variant: 'ghost',
          },
          {
            title: `Delete`,
            theme: 'danger',
            isConfirm: true,
            handler: () => store.deleteAsset(props.item.id),
          },
        ],
      }),
  },
]
})

const navigate = () => {
  const { type, id } = item.value

  router.push({
    name: type === 'folder' ? 'CollabFolder' : 'CollabFile',
    params: type === 'folder' ? { folderId: id } : { fileId: id },
  })
}
</script>

<template>
  <tr :class="$style.row">
    <td style="width: 70px" @click.prevent="() => store.toggleId(item.id)">
      <div :class="$style.checkbox">
        <Checkbox :model-value="props.checked || false" />
      </div>
    </td>

    <td width="32">
      <Popover :disabled="item.type === 'file' || rename.isActive" >
        <template #content>
          <IconSelector
            :icon="icon"
            :color="color"
            @update="(data) => store.updateAsset(item.id, data)"
          />
        </template>
        <Button variant="ghost" theme="neutral">
          <template #icon>
            <span :class="$style.pipelineIcon">
              <Icon
                :icon="rename.isActive ? 'pencil' : icon"
                :size="18"
                :class="$style['icon-color']"
              />
            </span>
          </template>
        </Button>
      </Popover>
    </td>

    <td v-if="rename.isActive" :class="$style.title">
      <input
        v-model="rename.name"
        v-focus
        :class="$style.textField"
        @keypress.enter="handleRename"
      />
    </td>

    <td v-else :class="$style.title" @click="navigate">
      {{ item.title }}
    </td>

    <td :class="$style.meta">
      {{ item.type==='folder' ? `${item.subAssetsCount} files`: '' }}
    </td>

    <td :class="$style.meta">
      {{ ISODate(item.createdAt, { format: 'LLL dd' }) }}
    </td>

    <td :class="$style.meta">
      <MembersPreview :members="item.members ?? []"/>
    </td>

    <td width="32">
      <DropDown :width="200" :menu="menu" :close-on-select="true">
        <Button
          size="2"
          variant="ghost"
          theme="neutral"
          icon="dotGrid1x3Horizontal"
        />
      </DropDown>
      <Confirm ref="confirmPopup" />

      <Popup ref="membersPopup" title="Members">
        <ManageMembers
          :members="item.members ?? []"
          @add="(member: User) => store.addAssetMember(item.id, member)"
          @remove="(member: User) => store.removeAssetMember(item.id, member)"
          :empty-text="`Nobody has access to this ${item.type} yet. Invite workspace members to give the access to this ${item.type}.`"
        />
      </Popup>

      <Popup ref="moveToFolderPopup" title="Move Folder">
        <MoveToFolder :item="item" :folder="item.parent" @submit="handleMoveFolder"  @cancel="moveToFolderPopup?.hide()"/>
      </Popup>
    </td>
  </tr>
</template>


<style module lang="scss">
@import url(./common.module.scss);
.title {
  @extend .medium-3;
}
.meta {
  @extend .regular-3;
  color: var(--neutral-11);
}
.row {
  cursor: pointer;

  .icon-color {
    color: v-bind('color') !important;
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
