import type App from '@/models/app'
import { Subscription } from '@/models/Subscription'
import type { WorkspaceMember } from '@/models/user'
import type Workspace from '@/models/workspace'
import type WorkspaceInvitation from '@/models/workspaceInvitation'
import type { AxiosInstance } from 'axios'
import Notification from '@/models/notification.ts'
import { Pagination } from '@/utils/api/types.ts'

export interface PlanDetails {
  price: number
  previous: number
  trialDays: number
}
export class WorkspaceService {
  constructor(private instance: AxiosInstance) {}

  create = (body: { title: string }) =>
    this.instance.post<Workspace>(`/workspace`, body).then((res) => res.data)

  // ! SHOULD create an empty workspace in BE for onboarding flow
  // if this is last workspace for user
  deleteWorkspace = () => undefined

  update = (body: Partial<Workspace>) =>
    this.instance.put(``, body).then((res) => res.data)

  uploadLogo = (file: File) => {
    const formData = new FormData()
    formData.append('logo', file)
    return this.instance.post<string>(`/logo`, formData).then((res) => res.data)
  }

  uploadFile = (file: File) => {
      const formData = new FormData()
      formData.append('file', file)
      return this.instance.post<string>(`/files`, formData).then((res) => res.data)
  }

  updateUserRole = (userId: number, role: WorkspaceMember['role']) =>
    this.instance.put(`/members/${userId}`, { role }).then((res) => res.data)

  removeMember = (userId: number) =>
    this.instance.delete(`/members/${userId}`).then((res) => res.data)

  members = () =>
    this.instance.get<WorkspaceMember[]>(`/members`).then((res) => res.data)

  apps = () => this.instance.get<App[]>(`/apps`).then((res) => res.data)

  allApps = () =>
    this.instance.get<App[]>(`/marketplace/apps`).then((res) => res.data)

  addVote = (title: string) =>
    this.instance.post<boolean>(`/add-vote`, { title }).then((res) => res.data)

  checkVote = () =>
    this.instance.get('/check-vote').then((res) => {
      return res.data === 1 ? true : false
    })

  inviteMember = (email: string) =>
    this.instance
      .post<WorkspaceInvitation>('/invitations', { email })
      .then((res) => res.data)

  invitations = () =>
    this.instance
      .get<WorkspaceInvitation[]>(`/invitations`)
      .then((res) => res.data)

  removeInvitation = (id: number) =>
    this.instance.delete(`/invitations/${id}`).then((res) => res.data)

  getApp = (id: number) =>
    this.instance.get<App>(`/marketplace/apps/${id}`).then((res) => res.data)

  installApp = (id: number) =>
    this.instance
      .post<App[]>(`/install-app`, { appId: id })
      .then((res) => res.data)

  uninstallApp = (id: number) =>
    this.instance.delete(`/uninstall-app/${id}`).then((res) => res.data)

  getNotificationSettings = () =>
    this.instance.get('/notification/settings').then((res) => res.data)

  saveNotificationSettings = (data: object) =>
    this.instance.post('/notification/settings', data).then((res) => res.data)

  getNotifications = (params = {}): Promise<Pagination<Notification>> =>
    this.instance.get('/notifications', { params }).then((res) => res.data)

  readNotification = (id: string) =>
    this.instance.put(`/notifications/${id}`).then((res) => res.data)

  deleteNotification = (id: string) =>
    this.instance.delete(`/notifications/${id}`).then((res) => res.data)

  readAllNotifications = () =>
    this.instance.post('/notifications/read').then((res) => res.data)

  getUnreadNotificationSummary = () =>
    this.instance.get('/notifications/unread-summary').then((res) => res.data)

  planDetails = () =>
    this.instance.get<PlanDetails>('/billing/plan').then((res) => res.data)

  billingIntent = () =>
    this.instance
      .post<{ secret: string }>('/billing/intent')
      .then((res) => res.data)

  upgradePremium = (paymentMethod: string, appId?: number) =>
    this.instance
      .post('/billing/subscription', { paymentMethod, appId })
      .then((res) => res.data)

  resumePremium = () =>
    this.instance.post('/billing/resume').then((res) => res.data)

  subscription = () =>
    this.instance
      .get<Subscription>('/billing/subscription')
      .then((res) => res.data)

  cancelPremium = () =>
    this.instance.delete('/billing/subscription').then((res) => res.data)

  portalLink = () =>
    this.instance.get<string>('/billing/portal').then((res) => res.data)
}
