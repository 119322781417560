<script setup lang="ts">
import Icon from '@/components/common/Icon.vue'
import Button from '@/components/common/Button.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

defineProps<{ type: 'file' | 'folder' }>()

const backHome = () => router.push({ name: 'CollabHome' })
</script>

<template>
  <div :class="['popup-content', $style.container]">
    <div :class="$style.content">
      <span>
        <Icon icon="bubbleCross" />
      </span>

      <div>
        <p>You don’t have permission to view this {{ type }}.</p>
        <p>Request access from the owner to proceed.</p>
      </div>
    </div>
  </div>

  <div :class="['popup-content', 'popup-actions']">
    <Button theme="neutral" variant="solid" size="3" @click="backHome">
      Back to Home
    </Button>
  </div>
</template>

<style module lang="scss">
.container {
  width: 420px;

  .content {
    @extend .regular-4;
    color: var(--neutral-12, #1E1F24);
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 32px;
  }
}
</style>
