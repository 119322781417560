import Home from './Home.vue'
import Folder from './Folder.vue'
import File from './File.vue'
import Base from './index.vue'
import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    name: 'Collab',
    path: 'collab',
    meta: { title: 'Collab' },
    components: { default: Base },
    redirect: { name: 'CollabHome' },
    children: [
      {
        name: 'CollabHome',
        path: '',
        component: Home,
        meta: { title: 'Collab | Home' },
      },
      {
        name: 'CollabFolder',
        path: 'folders/:folderId',
        component: Folder,
        meta: { title: 'Folder' },
      },
      {
        name: 'CollabFile',
        path: 'files/:fileId',
        component: File,
        meta: { title: 'File' },
      },
    ],
  },
]

export default routes
