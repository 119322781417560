<template>
  <bubble-menu v-if="shouldShow" :editor="editor" :tippy-options="{ duration: 100 }">
    <div :class="$style.container">
      <DropDown :width="150" :menu="fontSizeMenu" :close-on-select="true">
        <MenuItem
          :icon="selectedFontSizeIcon"
          :is-active="true"
          @click="editor.chain().focus().toggleBold().run()"
        />
      </DropDown>

      <MenuItem
        icon="bold"
        :is-active="editor.isActive('bold')"
        @click="editor.chain().focus().toggleBold().run()"
      />

      <MenuItem
        icon="italic"
        :is-active="editor.isActive('italic')"
        @click="editor.chain().focus().toggleItalic().run()"
      />

      <MenuItem
        icon="underline"
        :is-active="editor.isActive('underline')"
        @click="editor.chain().focus().toggleUnderline().run()"
      />

      <MenuItem
        icon="strikethrough"
        :is-active="editor.isActive('strike')"
        @click="editor.chain().focus().toggleStrike().run()"
      />

      <MenuItem
        icon="highlight"
        :is-active="editor.isActive('highlight')"
        @click="highlightColorPicker?.click()"
      />

      <input
        ref="highlightColorPicker"
        type="color"
        style="height: 0; width: 0; border: 0; padding: 0;"
        @input="
          (e: Event) =>
            editor
              .chain()
              .focus()
              .toggleHighlight({ color: (e.target as HTMLInputElement)?.value })
              .run()
        "
      />

      <MenuItem
        icon="color"
        :is-active="editor.isActive('textStyle')"
        @click="textColorPicker?.click()"
      />

      <input
        ref="textColorPicker"
        type="color"
        style="height: 0; width: 0; border: 0; padding: 0"
        @input="
          (e) =>
            editor
              .chain()
              .focus()
              .setColor((e.target as HTMLInputElement)?.value)
              .run()
        "
      />

      <Popover :placement="'bottom'">
        <Button
          icon="link"
          size="2"
          :variant="editor.isActive('link') ? 'soft' : 'ghost'"
          theme="neutral"
          @click="link = editor.getAttributes('link').href ?? ''"
        />

        <template #content="{ close }">
          <div :class="$style['link-container']">
            <input v-model.trim="link" type="url" :class="$style.textField" />

            <Button
              variant="solid"
              theme="neutral"
              size="2"
              @click="
                () => {
                  setLink()
                  close()
                }
              "
            >
              Set Link
            </Button>
          </div>
        </template>
      </Popover>

      <MenuItem
        v-for="option in alignmentOptions"
        :key="option.value"
        :icon="option.icon"
        :is-active="editor.isActive({ textAlign: option.value })"
        @click="editor.chain().focus().setTextAlign(option.value).run()"
      />
    </div>
  </bubble-menu>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Editor } from '@tiptap/core'
import { BubbleMenu } from '@tiptap/vue-3'
import {
  Icon,
  Button,
  Popover,
  DropDown,
  DropDownItem,
} from '@/components/common'

import MenuItem from './MenuItem.vue'

const props = defineProps<{ editor: Editor }>()

const highlightColorPicker = ref<HTMLInputElement | null>(null)
const textColorPicker = ref<HTMLInputElement | null>(null)

interface AlignmentOption {
  value: string
  icon: InstanceType<typeof Icon>['$props']['icon']
}

const alignmentOptions = ref<AlignmentOption[]>([
  { value: 'left', icon: 'textAlignLeft' },
  { value: 'center', icon: 'textAlignCenter' },
  { value: 'right', icon: 'textAlignRight' },
  { value: 'justify', icon: 'textAlignJustify' },
])

const selectedFontSizeIcon = computed(() => {
  if (props.editor.isActive('heading', { level: 1 })) {
    return 'heading1'
  } else if (props.editor.isActive('heading', { level: 2 })) {
    return 'heading2'
  } else if (props.editor.isActive('heading', { level: 3 })) {
    return 'heading3'
  } else {
    return 'paragraph'
  }
})

const shouldShow = computed(() => {
  return props.editor.isActive('heading') || props.editor.isActive('paragraph') || props.editor.isActive('link')
})

const link = ref('')

const fontSizeMenu: DropDownItem[] = [
  {
    id: 'paragraph',
    icon: 'paragraph',
    title: 'Paragraph',
    handler: () =>  props.editor.chain().focus().setParagraph().run()
  },
  {
    id: 'heading1',
    icon: 'heading1',
    title: 'Heading 1',
    handler: () => props.editor.chain().focus().toggleHeading({ level: 1 }).run()
  },
  {
    id: 'heading2',
    icon: 'heading2',
    title: 'Heading 2',
    handler: () => props.editor.chain().focus().toggleHeading({ level: 2 }).run()
  },
  {
    id: 'heading3',
    icon: 'heading3',
    title: 'Heading 3',
    handler: () => props.editor.chain().focus().toggleHeading({ level: 3 }).run()
  },
]

const setLink = () => {
      if (link.value === '') {
        props
          .editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      props
        .editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: link.value })
        .run()
    }
</script>

<style lang="scss" module>
.container {
  width: 480px;
  display: flex;
  padding: 4px 8px;
  background-color: var(--panel-translucent);
  border-radius: 8px;
  border: 1px solid var(--neutral-alpha-4);
  position: relative;
  gap: 4px;
}

.link-container {
  padding: 12px;
  display: flex;
  width: 360px;
  gap: 12px;

  .textField {
    @extend .medium-3;
    border: none;
    width: 100%;
    background-color: var(--neutral-alpha-3);
    padding: 0px 12px;
    border-radius: 6px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--neutral-8);
    }
}

}
</style>
