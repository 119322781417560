<script setup lang="ts">
import { toRefs } from 'vue'
import Icon from './Icon.vue'
import { computed } from 'vue';

type IconInstance = InstanceType<typeof Icon>

const props = defineProps<{
  variant?: 'solid' | 'soft' | 'outline' | 'ghost' | 'alternative'
  theme?: 'primary' | 'accent' | 'neutral' | 'danger'
  size?: '1' | '2' | '3' | '4' | '5'
  icon?: IconInstance['$props']['icon']
  iconRight?: IconInstance['$props']['icon']
  loading?: boolean
  as?: string
}>()
const { variant, theme, size, icon, loading, as } = toRefs(props)

const style = computed(() => `${theme?.value || 'primary'}-${variant?.value || 'solid'}`)
</script>

<template>
  <component
    :is="as || 'button'"
    :class="[
      $style.button,
      $style[style],
      $style[`size-${size}`],
      $slots.default === undefined && $style['only-icon'],
    ]"
    :disabled="loading"
  >
    <slot v-if="!loading" name="icon" />
    <Icon v-if="loading === true" icon="vanillaIcon" />
    <Icon v-if="icon && !loading && !$slots.icon" :icon="icon" />
    <span v-if="$slots.default" :class="$style.label">
      <slot />
    </span>
    <Icon v-if="iconRight" :icon="iconRight" />
  </component>
</template>

<style module lang="scss">
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  cursor: pointer;
  box-sizing: border-box;
  flex-shrink: 0;
  border: none;
  transition:
    color 0.15s,
    background-color 0.15s,
    border-color 0.15s,
    outline-width 0.15s;
  background-color: unset;
  font-family: inherit;

  &.only-icon {
    padding: 0;
  }

  &:disabled {
    background-color: var(--panel-3);
    color: var(--neutral-alpha-8);
    pointer-events: none;
  }

  & > i {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.size {
  &-1 {
    @extend .medium-2;
    height: 24px;
    padding: 0px 8px;
    border-radius: 4px;

    i {
      width: 16px;
      height: 16px;
    }
    &.only-icon {
      padding: 0;
      width: 24px;
    }
  }

  &-2 {
    @extend .medium-3;
    height: 32px;
    padding: 0px 12px;
    border-radius: 6px;

    i {
      width: 18px;
      height: 18px;
    }
    &.only-icon {
      padding: 0;
      width: 32px;
    }
  }

  &-3 {
    @extend .medium-3;
    height: 40px;
    padding: 0px 12px;
    border-radius: 8px;

    i {
      width: 20px;
      height: 20px;
    }
    &.only-icon {
      padding: 0;
      width: 40px;
    }
  }

  &-4 {
    @extend .medium-4;
    height: 48px;
    padding: 0px 16px;
    border-radius: 10px;

    i {
      width: 24px;
      height: 24px;
    }
    &.only-icon {
      padding: 0;
      width: 48px;
    }
  }

  &-5 {
    @extend .medium-5;
    height: 56px;
    padding: 0px 20px;
    border-radius: 12px;

    i {
      width: 24px;
      height: 24px;
    }
    &.only-icon {
      padding: 0;
      width: 56px;
    }
  }
}

.primary {
  &-solid {
    color: var(--basic-white);
    background-color: var(--primary-9);

    &:hover {
      background-color: var(--primary-10);
    }
  }

  &-soft {
    color: var(--primary-alpha-11);
    background-color: var(--primary-alpha-3);

    &:hover {
      background-color: var(--primary-alpha-4);
    }
  }

  &-outline {
    outline: 1px solid var(--primary-alpha-6);
    color: var(--primary-alpha-11);

    &:hover {
      outline: none;
      background-color: var(--primary-alpha-2);
    }
  }

  &-ghost {
    color: var(--primary-alpha-11);

    &:hover {
      outline: none;
      background-color: var(--primary-alpha-3);
    }
  }
}

.accent {
  &-solid {
    color: var(--basic-white);
    background-color: var(--accent-9);

    &:hover {
      background-color: var(--accent-10);
    }
  }

  &-soft {
    color: var(--accent-alpha-11);
    background-color: var(--accent-alpha-3);

    &:hover {
      background-color: var(--accent-alpha-4);
    }
  }

  &-outline {
    outline: 1px solid var(--accent-alpha-6);
    color: var(--accent-alpha-11);

    &:hover {
      outline: none;
      background-color: var(--accent-alpha-2);
    }
  }

  &-ghost {
    color: var(--accent-alpha-11);

    &:hover {
      outline: none;
      background-color: var(--accent-alpha-3);
    }
  }
}

.neutral {
  &-solid {
    color: var(--neutral-1);
    background-color: var(--neutral-12);

    &:hover {
      opacity: 0.82;
    }
  }

  &-soft {
    color: var(--neutral-12);
    background-color: var(--neutral-alpha-3);

    &:hover {
      background-color: var(--neutral-alpha-4);
    }
  }

  &-outline {
    outline: 1px solid var(--neutral-alpha-6);
    color: var(--neutral-12);

    &:hover {
      outline: none;
      background-color: var(--neutral-alpha-2);
    }
  }

  &-ghost {
    color: var(--neutral-12);

    &:hover {
      outline: none;
      background-color: var(--neutral-alpha-3);
    }
  }
}

.danger {
  &-solid {
    color: var(--basic-white);
    background-color: var(--danger-9);

    &:hover {
      background-color: var(--danger-10);
    }
  }

  &-soft {
    color: var(--danger-alpha-11);
    background-color: var(--danger-alpha-3);

    &:hover {
      background-color: var(--danger-alpha-4);
    }
  }

  &-outline {
    outline: 1px solid var(--danger-alpha-6);
    color: var(--danger-alpha-11);

    &:hover {
      outline: none;
      background-color: var(--danger-alpha-2);
    }
  }

  &-ghost {
    color: var(--danger-alpha-11);

    &:hover {
      outline: none;
      background-color: var(--danger-alpha-3);
    }
  }
}
</style>
