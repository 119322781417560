<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
<script setup lang="ts">
import { ref, provide } from 'vue'
import Button from './Button.vue'
import gsap from 'gsap'
import { PopupKey } from './types'

interface Props {
  title?: string
  persistent?: boolean // don't close on outside click
  hideCloseButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  persistent: false,
  hideCloseButton: false,
})

const emit = defineEmits<{
  (e: 'hide'): void
}>()

const isOpen = ref<boolean>(false)

const show = () => {
  isOpen.value = true
}

const hide = () => {
  isOpen.value = false
  emit('hide')
}

const handleOverlayClick = () => {
  !props.persistent && hide()
}

const beforeEnter = (element: Element) => {
  const el = element as HTMLElement
  el.style.opacity = '0'
  const popup = el.querySelector('.popup') as HTMLDivElement
  if (popup) popup.style.transform = 'translateY(200px)'
}

const enter = (element: Element) => {
  const el = element as HTMLElement
  const popup = el.querySelector('.popup')
  gsap.to(el, {
    duration: 0.3,
    opacity: 1,
    ease: 'power2.out',
  })
  gsap.to(popup, {
    duration: 0.15,
    translateY: '0vh',
    ease: 'power2.out',
  })
}

const beforeLeave = (element: Element) => {
  const el = element as HTMLElement
  el.style.opacity = '1'
}

const leave = (element: Element, done: () => void) => {
  const el = element as HTMLElement
  gsap.to(el, {
    duration: 0.2,
    opacity: 0,
    ease: 'power2.out',
    onComplete: done,
  })
}

provide(PopupKey.show, show)
provide(PopupKey.hide, hide)
defineExpose({ show, hide })
</script>

<template>
  <Teleport to="body">
    <transition
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div v-if="isOpen" :class="$style.outer">
        <div :class="$style.overlay" @click="handleOverlayClick" />
        <div v-bind="$attrs" :class="[$style.popup, 'popup']">
          <div :class="[$style.header, !title && $style.noTitle]">
            <h3 :class="$style.title">{{ title }}</h3>
            <Button
              v-if="!hideCloseButton"
              theme="neutral"
              variant="ghost"
              size="2"
              icon="crossSmall"
              @click="hide"
            />
          </div>
          <slot />
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<style module lang="scss">
.outer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 50;
  background-color: var(--overlay-dark-11);
  backdrop-filter: blur(8px);
}

.overlay {
  position: absolute;
  inset: 0;
}

.popup {
  background-color: var(--panel-translucent);
  border-radius: 16px;
  border: 1px solid var(--neutral-alpha-4);
}

.header {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid var(--neutral-alpha-4);

  &.noTitle {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    z-index: 1;
  }
}

.title {
  @extend .semibold-4;
  margin-right: 12px;
  flex-grow: 1;
}
</style>

<style lang="css">
.popup-content {
  padding: 20px;
}
.popup-actions {
  display: flex;
  gap: 8px;
  border-top: 1px solid var(--neutral-alpha-4);

  > button {
    flex: 1;
  }
}
</style>
