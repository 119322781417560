<template>
  <NodeViewWrapper>
    <div :class="$style.uploader">
      <Button icon="image" variant='solid' theme="neutral" size="2" @click="fileInput?.click()">Upload Image</Button>
      <input type="file" ref="fileInput" accept=".jpg,.jpeg,.png,.webp,.gif" @change="onChange"/>
    </div>
  </NodeViewWrapper>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {Button} from '@/components/common'
import { NodeViewWrapper } from '@tiptap/vue-3'
import { useWorkspaceStore } from '@/store/workspace'
import {NodeViewProps} from  '@tiptap/core'

const props = defineProps<NodeViewProps>();
const store = useWorkspaceStore()

const upload = ref<boolean>(false);
const fileInput = ref<HTMLInputElement | null>(null);

const onChange = (e: Event) => {
  const target = e.target as HTMLInputElement
  if (target.files && target.files?.length) {
    upload.value = true;
    store.api.workspace.uploadFile( target.files[0])
      .then((url: string) => {
        props.editor.chain().setImage({ src: url }).deleteRange({ from: props.getPos(), to: props.getPos() }).focus().run()
      })
      .finally(() => upload.value = false)
  }
}
</script>

<style lang="scss" module>
.uploader {
  border-radius: .25rem;
  border-width: 2px;
  border-style: dotted;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  padding: .5rem;
  display: flex;
  justify-content: center;
  padding: 32px 0px;

  input {
    display: none;
  }
}
</style>
