<script setup lang="ts">
import AssigneeBadge from '@/components/common/AssigneeBadge.vue'
import User from '@/models/user'
import { computed } from 'vue'

const props = defineProps<{members: User[]}>()
const membersCount = computed(() => props.members.length)
</script>

<template>
  <div :class="$style.container">
    <AssigneeBadge :users="members" :avatar-only="true" />

    {{ membersCount ?  `${membersCount} member${membersCount > 1 ? 's' : ''}` : 'Unassigned'}}
  </div>
</template>

<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  color: var(--neutral-11);
  @extend .regular-3;
}
</style>
