import { Node, VueNodeViewRenderer } from '@tiptap/vue-3'
import ImageUploader from './ImageUploader.vue'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageUpload: {
      setImageUpload: () => ReturnType
    }
  }
}

export const ImageUpload = Node.create({
  name: 'imageUpload',

  isolating: true,

  defining: true,

  group: 'block',

  draggable: true,

  selectable: true,

  inline: false,

  parseHTML() {
    return [
      {
        tag: `div[data-type="imageUpload"]`,
      },
    ]
  },

  renderHTML() {
    return ['div', { 'data-type': this.name }]
  },

  addCommands() {
    return {
      setImageUpload:
        () =>
        ({ commands }) =>
          commands.insertContent(`<div data-type="imageUpload"></div>`),
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageUploader)
  },
})

export default ImageUpload
