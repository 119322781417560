<template>
  <div :class="$style['dropdown-menu']">
    <template v-if="items.length">
      <Button
        :variant="index === selectedIndex ? 'soft' : 'ghost'"
        :icon="item.icon"
        size="2"
        theme="neutral"
        v-for="(item, index) in items"
        :key="index"
        @click="selectItem(index)"
      >
        {{ item.title }}
      </Button>
    </template>
    <div class="item" v-else>
      No result
    </div>
  </div>
</template>

<script>
import { Button } from '@/components/common'

export default {
  components: {
    Button,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command(item)
      }
    },
  },
}
</script>

<style lang="scss" module>
/* Dropdown menu */
.dropdown-menu {
  background-color: var(--panel-translucent);
  border-radius: 8px;
  border: 1px solid var(--neutral-alpha-4);
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  overflow: auto;
  padding: 0.4rem;
  position: relative;

  button {
    justify-content: left;
  }

}
</style>
