<template>
  <Button
    :variant="isActive ? 'soft' : 'ghost'"
    :icon="icon"
    size="2"
    theme="neutral"
    @click="emit('click')"
  />
</template>

<script setup lang="ts">
import { Button, Icon } from '@/components/common'

type IconInstance = InstanceType<typeof Icon>

defineProps<{ isActive: boolean; icon: IconInstance['$props']['icon'] }>()

const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>
