<script lang="ts" setup>
import draggable from 'vuedraggable'
import { SortableEvent } from '@/modules/crm/components/BoardView/types'
import { Asset } from '../models/asset'
import { useCollabStore } from '../store'
import { findSiblings, getNewLexorank } from '@/utils/board'
import BulkActions from './actions/BulkActions.vue'
import AssetRow from './AssetRow.vue'

const store = useCollabStore()

const handleSort = (event: SortableEvent<Asset>) => {
  const {
    item: { _underlying_vm_ },
    newIndex,
    oldIndex,
  } = event

  const asset = { ..._underlying_vm_ }
  const payload = findSiblings(newIndex, oldIndex, store.assets)
  const rank = getNewLexorank(payload)

  store.updateAsset(asset.id, { rank: rank.toString() })
}
</script>
<template>
  <table :class="$style.table">
    <thead>
      <BulkActions
        v-if="store.selectedIds.length"
        :selected-ids="store.selectedIds"
        :total="store.assets.length"
        @check-all="
          (all: boolean) =>
        (store.selectedIds = all ? store.assets.map(asset => asset.id) : [])
        "
      />
    </thead>

    <draggable
      tag="tbody"
      :list="store.assets"
      animation="200"
      group="assets"
      item-key="id"
      @update="handleSort"
    >
      <template #item="{ element: asset }: { element: Asset }">
        <AssetRow
          :item="asset"
          :checked="store.selectedIds.includes(asset.id)"
        />
      </template>
    </draggable>
  </table>
</template>

<style lang="scss" module>
.table {
  width: 100%;

  tr {
    td {
      padding: 12px 0px;
      vertical-align: middle;
      transition: background-color 0.1s;
      padding-left: 16px;

      &:last-child {
        padding-right: 16px;
      }
    }

    &:hover {
      td {
        background-color: var(--neutral-alpha-3);
      }
    }
  }

  tr:not(:last-child) {
    td {
      border-bottom: 1px solid var(--neutral-alpha-3);
    }
  }
}
</style>
